export const GQLOperations = {
  Query: {
    ChangesetDiff: 'ChangesetDiff',
    LoadMoreComments: 'LoadMoreComments',
    CommentThreadsList: 'CommentThreadsList',
    ThreadCount: 'ThreadCount',
    ProductLabel: 'ProductLabel',
    Session: 'Session',
    FileVersions: 'FileVersions',
    EthnoArtLookup: 'EthnoArtLookup',
    FilmLookup: 'FilmLookup',
    FundingAccountLookup: 'FundingAccountLookup',
    LanguageLookup: 'LanguageLookup',
    LocationLookup: 'LocationLookup',
    FieldRegionLookup: 'FieldRegionLookup',
    FieldZoneLookup: 'FieldZoneLookup',
    OrganizationLookup: 'OrganizationLookup',
    PartnerLookup: 'PartnerLookup',
    ProjectLookup: 'ProjectLookup',
    TranslationProjectLookup: 'TranslationProjectLookup',
    InternshipProjectLookup: 'InternshipProjectLookup',
    StoryLookup: 'StoryLookup',
    UserLookup: 'UserLookup',
    ProgressReports: 'ProgressReports',
    Engagement: 'Engagement',
    EthnoArts: 'EthnoArts',
    Films: 'Films',
    Stories: 'Stories',
    Language: 'Language',
    LanguagePostList: 'LanguagePostList',
    Languages: 'Languages',
    Location: 'Location',
    Partner: 'Partner',
    PartnerPostList: 'PartnerPostList',
    PartnerDetailEngagements: 'PartnerDetailEngagements',
    PartnerProjects: 'PartnerProjects',
    Partners: 'Partners',
    ProjectPartnerships: 'ProjectPartnerships',
    ProductInfoForCreate: 'ProductInfoForCreate',
    ProductDetail: 'ProductDetail',
    ProductInfoForEdit: 'ProductInfoForEdit',
    ProductList: 'ProductList',
    CompletionDescriptionLookup: 'CompletionDescriptionLookup',
    AvailableProductSteps: 'AvailableProductSteps',
    ProgressReportDetail: 'ProgressReportDetail',
    ProgressReportEdit: 'ProgressReportEdit',
    ProgressReportsOfEngagement: 'ProgressReportsOfEngagement',
    ProjectBudget: 'ProjectBudget',
    ProjectChangeRequestList: 'ProjectChangeRequestList',
    ProjectRootDirectory: 'ProjectRootDirectory',
    ProjectDirectory: 'ProjectDirectory',
    EngagementList: 'EngagementList',
    ProjectList: 'ProjectList',
    ProjectMembers: 'ProjectMembers',
    GetUserRoles: 'GetUserRoles',
    ProjectOverview: 'ProjectOverview',
    ProjectEngagementListOverview: 'ProjectEngagementListOverview',
    ProjectPostList: 'ProjectPostList',
    NarrativeReports: 'NarrativeReports',
    FinancialReports: 'FinancialReports',
    ProjectFlowchart: 'ProjectFlowchart',
    NotificationList: 'NotificationList',
    Search: 'Search',
    User: 'User',
    Users: 'Users'
  },
  Mutation: {
    createComment: 'createComment',
    UpdateComment: 'UpdateComment',
    DeleteComment: 'DeleteComment',
    UpdatePeriodicReport: 'UpdatePeriodicReport',
    UploadPeriodicReportFile: 'UploadPeriodicReportFile',
    TogglePinned: 'TogglePinned',
    RequestFileUpload: 'RequestFileUpload',
    CreateFileVersion: 'CreateFileVersion',
    RenameFileNode: 'RenameFileNode',
    DeleteFileNode: 'DeleteFileNode',
    CreatePost: 'CreatePost',
    DeletePost: 'DeletePost',
    UpdatePost: 'UpdatePost',
    ChangePassword: 'ChangePassword',
    ForgotPassword: 'ForgotPassword',
    Login: 'Login',
    Logout: 'Logout',
    Register: 'Register',
    ResetPassword: 'ResetPassword',
    UpdateCeremony: 'UpdateCeremony',
    DeleteEngagement: 'DeleteEngagement',
    UpdateInternshipEngagement: 'UpdateInternshipEngagement',
    UpdateLanguageEngagement: 'UpdateLanguageEngagement',
    UploadLanguageEngagementPnp: 'UploadLanguageEngagementPnp',
    UploadInternshipEngagementGrowthPlan: 'UploadInternshipEngagementGrowthPlan',
    createInternshipEngagement: 'createInternshipEngagement',
    createLanguageEngagement: 'createLanguageEngagement',
    CreateEthnoArt: 'CreateEthnoArt',
    CreateFilm: 'CreateFilm',
    CreateStory: 'CreateStory',
    createLanguage: 'createLanguage',
    RemoveLocationFromLanguage: 'RemoveLocationFromLanguage',
    UpdateLanguage: 'UpdateLanguage',
    AddLocationToLanguage: 'AddLocationToLanguage',
    CreateLocation: 'CreateLocation',
    UpdateLocation: 'UpdateLocation',
    CreateFundingAccount: 'CreateFundingAccount',
    createOrganization: 'createOrganization',
    CreatePartner: 'CreatePartner',
    UpdatePartner: 'UpdatePartner',
    CreatePartnership: 'CreatePartnership',
    UpdatePartnership: 'UpdatePartnership',
    DeletePartnership: 'DeletePartnership',
    CreateDirectScriptureProduct: 'CreateDirectScriptureProduct',
    CreateDerivativeScriptureProduct: 'CreateDerivativeScriptureProduct',
    CreateOtherProduct: 'CreateOtherProduct',
    UpdateStepProgress: 'UpdateStepProgress',
    UpdateDirectScriptureProduct: 'UpdateDirectScriptureProduct',
    UpdateDerivativeScriptureProduct: 'UpdateDerivativeScriptureProduct',
    UpdateOtherProduct: 'UpdateOtherProduct',
    DeleteProduct: 'DeleteProduct',
    UpdatePartnershipsProducingMediums: 'UpdatePartnershipsProducingMediums',
    CreateCommunityStory: 'CreateCommunityStory',
    UpdateCommunityStoryResponse: 'UpdateCommunityStoryResponse',
    ChangeProgressReportCommunityStoryPrompt: 'ChangeProgressReportCommunityStoryPrompt',
    ExplainProgressVariance: 'ExplainProgressVariance',
    CreateMedia: 'CreateMedia',
    UpdateMedia: 'UpdateMedia',
    DeleteMedia: 'DeleteMedia',
    TransitionProgressReport: 'TransitionProgressReport',
    CreateProgressReportNews: 'CreateProgressReportNews',
    UpdateProgressReportNewsResponse: 'UpdateProgressReportNewsResponse',
    ReextractPnpProgress: 'ReextractPnpProgress',
    UpdateProjectBudgetUniversalTemplate: 'UpdateProjectBudgetUniversalTemplate',
    UpdateProjectBudgetRecord: 'UpdateProjectBudgetRecord',
    CreateProjectChangeRequest: 'CreateProjectChangeRequest',
    UpdateProjectChangeRequest: 'UpdateProjectChangeRequest',
    DeleteProjectChangeRequest: 'DeleteProjectChangeRequest',
    CreateProject: 'CreateProject',
    DeleteProject: 'DeleteProject',
    CreateProjectDirectory: 'CreateProjectDirectory',
    MoveFileNode: 'MoveFileNode',
    CreateProjectMember: 'CreateProjectMember',
    UpdateProjectMember: 'UpdateProjectMember',
    DeleteProjectMember: 'DeleteProjectMember',
    TransitionProject: 'TransitionProject',
    UpdateProject: 'UpdateProject',
    ReadNotification: 'ReadNotification',
    CreatePerson: 'CreatePerson',
    UpdateUser: 'UpdateUser'
  },
  Fragment: {
    ModifyChangesetDiffOnUpdate: 'ModifyChangesetDiffOnUpdate',
    RecalculateChangesetDiff: 'RecalculateChangesetDiff',
    ChangesetDiff: 'ChangesetDiff',
    ChangesetDiffItem: 'ChangesetDiffItem',
    ProjectChangesetDiff: 'ProjectChangesetDiff',
    BudgetRecordChangesetDiff: 'BudgetRecordChangesetDiff',
    Id: 'Id',
    ChangesetId: 'ChangesetId',
    Pagination: 'Pagination',
    DisplayLocation: 'DisplayLocation',
    DisplayFieldRegion: 'DisplayFieldRegion',
    DisplayFieldZone: 'DisplayFieldZone',
    Prompt: 'Prompt',
    promptResponseList: 'promptResponseList',
    promptResponse: 'promptResponse',
    variantResponse: 'variantResponse',
    Scripture: 'Scripture',
    securedDateRange: 'securedDateRange',
    SecuredPrompt: 'SecuredPrompt',
    DisplayUser: 'DisplayUser',
    variant: 'variant',
    BudgetOverview: 'BudgetOverview',
    comment: 'comment',
    commentThread: 'commentThread',
    EngagementBreadcrumb: 'EngagementBreadcrumb',
    engagementDataGridRow: 'engagementDataGridRow',
    FundingAccountCard: 'FundingAccountCard',
    InternshipEngagementListItem: 'InternshipEngagementListItem',
    LanguageEngagementListItem: 'LanguageEngagementListItem',
    LanguageListItem: 'LanguageListItem',
    LocationCard: 'LocationCard',
    MethodologiesCard: 'MethodologiesCard',
    OrganizationListItem: 'OrganizationListItem',
    PartnerListItem: 'PartnerListItem',
    PartnershipCard: 'PartnershipCard',
    PartnershipSummary: 'PartnershipSummary',
    PartnershipItem: 'PartnershipItem',
    PeriodicReport: 'PeriodicReport',
    SecuredPeriodicReport: 'SecuredPeriodicReport',
    ProductCard: 'ProductCard',
    ProgressReportBreadcrumb: 'ProgressReportBreadcrumb',
    ProgressReportOverviewItem: 'ProgressReportOverviewItem',
    ProgressReportOverview: 'ProgressReportOverview',
    ProjectBreadcrumb: 'ProjectBreadcrumb',
    ProjectChangeRequestListItem: 'ProjectChangeRequestListItem',
    ProjectChangeRequestSummary: 'ProjectChangeRequestSummary',
    projectDataGridRow: 'projectDataGridRow',
    ProjectListItem: 'ProjectListItem',
    ProjectMemberCard: 'ProjectMemberCard',
    ProjectMemberItem: 'ProjectMemberItem',
    ProjectMemberList: 'ProjectMemberList',
    LoggedInUser: 'LoggedInUser',
    features: 'features',
    TogglePin: 'TogglePin',
    UserListItem: 'UserListItem',
    workflow: 'workflow',
    workflowState: 'workflowState',
    workflowTransition: 'workflowTransition',
    FileVersionItem: 'FileVersionItem',
    FileNodeInfo: 'FileNodeInfo',
    FileInfo: 'FileInfo',
    EthnoArtLookupItem: 'EthnoArtLookupItem',
    FilmLookupItem: 'FilmLookupItem',
    FundingAccountLookupItem: 'FundingAccountLookupItem',
    LanguageLookupItem: 'LanguageLookupItem',
    OrganizationLookupItem: 'OrganizationLookupItem',
    PartnerLookupItem: 'PartnerLookupItem',
    ProjectLookupItem: 'ProjectLookupItem',
    StoryLookupItem: 'StoryLookupItem',
    UserLookupItem: 'UserLookupItem',
    PostToDelete: 'PostToDelete',
    PostForm: 'PostForm',
    PostList: 'PostList',
    PostListItemCard: 'PostListItemCard',
    PostableId: 'PostableId',
    progressReportsDataGridRow: 'progressReportsDataGridRow',
    Ceremony: 'Ceremony',
    CeremonyCard: 'CeremonyCard',
    EngagementToDelete: 'EngagementToDelete',
    EditEngagement: 'EditEngagement',
    InternshipEngagementDetail: 'InternshipEngagementDetail',
    MentorCard: 'MentorCard',
    TranslationProjectSensitivity: 'TranslationProjectSensitivity',
    LanguageEngagementDatesForm: 'LanguageEngagementDatesForm',
    EngagementDescription: 'EngagementDescription',
    LanguageEngagementHeader: 'LanguageEngagementHeader',
    LanguageEngagementDetail: 'LanguageEngagementDetail',
    EngagementPlanningSpreadsheet: 'EngagementPlanningSpreadsheet',
    FirstScripture: 'FirstScripture',
    LanguageDetail: 'LanguageDetail',
    LeastOfThese: 'LeastOfThese',
    LanguageForm: 'LanguageForm',
    LocationDetail: 'LocationDetail',
    LocationForm: 'LocationForm',
    partnerDetails: 'partnerDetails',
    organizationDetails: 'organizationDetails',
    partnerDetailPeople: 'partnerDetailPeople',
    partnerProjectDataGridRow: 'partnerProjectDataGridRow',
    projectOldPrimaryPartnerships: 'projectOldPrimaryPartnerships',
    ProjectsBudgetForPartnershipChange: 'ProjectsBudgetForPartnershipChange',
    PartnershipToCheckBudgetChange: 'PartnershipToCheckBudgetChange',
    PartnershipForm: 'PartnershipForm',
    CreateProductResult: 'CreateProductResult',
    ProductDetail: 'ProductDetail',
    PartnershipForLabel: 'PartnershipForLabel',
    ProductProgress: 'ProductProgress',
    StepProgress: 'StepProgress',
    CurrentProgressOfProduct: 'CurrentProgressOfProduct',
    UpdateProductResult: 'UpdateProductResult',
    ProductList: 'ProductList',
    EditPartnershipsProducingMediumsInfo: 'EditPartnershipsProducingMediumsInfo',
    ProductForm: 'ProductForm',
    Producible: 'Producible',
    ProgressRefsRelatingToEngagement: 'ProgressRefsRelatingToEngagement',
    ProgressReportRef: 'ProgressReportRef',
    ProductProgressRef: 'ProductProgressRef',
    ProgressReportDetail: 'ProgressReportDetail',
    ProgressSummary: 'ProgressSummary',
    ProgressOfProductForReport: 'ProgressOfProductForReport',
    ProductInfoForProgressReport: 'ProductInfoForProgressReport',
    varianceExplanation: 'varianceExplanation',
    workflowEvent: 'workflowEvent',
    ProgressReportEdit: 'ProgressReportEdit',
    ProgressReportProgress: 'ProgressReportProgress',
    ExplanationOfProgressStep: 'ExplanationOfProgressStep',
    mediaStep: 'mediaStep',
    progressReportMedia: 'progressReportMedia',
    visualMedia: 'visualMedia',
    ProgressReportStatus: 'ProgressReportStatus',
    ProgressReportListItem: 'ProgressReportListItem',
    pnpExtractionResult: 'pnpExtractionResult',
    pnpProblem: 'pnpProblem',
    BudgetRecord: 'BudgetRecord',
    CalculateNewTotal: 'CalculateNewTotal',
    ProjectCachedEngagementDateRanges: 'ProjectCachedEngagementDateRanges',
    ProjectCachedPartnershipDateRanges: 'ProjectCachedPartnershipDateRanges',
    CanDelete: 'CanDelete',
    ProjectDirectoryContents: 'ProjectDirectoryContents',
    ProjectOverview: 'ProjectOverview',
    ProjectEngagementList: 'ProjectEngagementList',
    projectWorkflowEvent: 'projectWorkflowEvent',
    notificationBase: 'notificationBase',
    notificationCommentViaMention: 'notificationCommentViaMention',
    notification: 'notification',
    notificationSystem: 'notificationSystem',
    SearchResultItem: 'SearchResultItem',
    UserForm: 'UserForm'
  }
} as const;

export const GqlSensitiveOperations: ReadonlySet<string> = new Set([
  'ChangePassword',
  'Login',
  'Logout',
  'Register',
  'ResetPassword'
]);
