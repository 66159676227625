import type * as Types from '~/api/schema.graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type EthnoArtLookupQueryVariables = Types.Exact<{
  query: Types.Scalars['String']['input'];
}>;


export type EthnoArtLookupQuery = { readonly search: (
    { readonly __typename?: 'SearchOutput' }
    & { readonly items: ReadonlyArray<{ readonly __typename?: 'DerivativeScriptureProduct' } | { readonly __typename?: 'DirectScriptureProduct' } | (
      { readonly __typename?: 'EthnoArt' }
      & Pick<Types.EthnoArt, 'id'>
      & { readonly name: (
        { readonly __typename?: 'SecuredString' }
        & Pick<Types.SecuredString, 'value'>
      ) }
    ) | { readonly __typename?: 'FieldRegion' } | { readonly __typename?: 'FieldZone' } | { readonly __typename?: 'Film' } | { readonly __typename?: 'FinancialReport' } | { readonly __typename?: 'FundingAccount' } | { readonly __typename?: 'InternshipProject' } | { readonly __typename?: 'Language' } | { readonly __typename?: 'Location' } | { readonly __typename?: 'MomentumTranslationProject' } | { readonly __typename?: 'MultiplicationTranslationProject' } | { readonly __typename?: 'NarrativeReport' } | { readonly __typename?: 'Organization' } | { readonly __typename?: 'OtherProduct' } | { readonly __typename?: 'Partner' } | { readonly __typename?: 'ProgressReport' } | { readonly __typename?: 'Story' } | { readonly __typename?: 'User' }> }
  ) };

export type EthnoArtLookupItemFragment = (
  { readonly __typename?: 'EthnoArt' }
  & Pick<Types.EthnoArt, 'id'>
  & { readonly name: (
    { readonly __typename?: 'SecuredString' }
    & Pick<Types.SecuredString, 'value'>
  ) }
);

export const EthnoArtLookupItemFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"EthnoArtLookupItem"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"EthnoArt"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"value"}}]}}]}}]} as unknown as DocumentNode<EthnoArtLookupItemFragment, unknown>;
export const EthnoArtLookupDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"EthnoArtLookup"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"query"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"search"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"query"},"value":{"kind":"Variable","name":{"kind":"Name","value":"query"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"type"},"value":{"kind":"ListValue","values":[{"kind":"EnumValue","value":"EthnoArt"}]}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"items"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"EthnoArt"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"EthnoArtLookupItem"}}]}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"EthnoArtLookupItem"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"EthnoArt"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"value"}}]}}]}}]} as unknown as DocumentNode<EthnoArtLookupQuery, EthnoArtLookupQueryVariables>;