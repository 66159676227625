import type * as Types from '~/api/schema.graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UserLookupQueryVariables = Types.Exact<{
  query: Types.Scalars['String']['input'];
}>;


export type UserLookupQuery = { readonly search: (
    { readonly __typename?: 'SearchOutput' }
    & { readonly items: ReadonlyArray<{ readonly __typename?: 'DerivativeScriptureProduct' } | { readonly __typename?: 'DirectScriptureProduct' } | { readonly __typename?: 'EthnoArt' } | { readonly __typename?: 'FieldRegion' } | { readonly __typename?: 'FieldZone' } | { readonly __typename?: 'Film' } | { readonly __typename?: 'FinancialReport' } | { readonly __typename?: 'FundingAccount' } | { readonly __typename?: 'InternshipProject' } | { readonly __typename?: 'Language' } | { readonly __typename?: 'Location' } | { readonly __typename?: 'MomentumTranslationProject' } | { readonly __typename?: 'MultiplicationTranslationProject' } | { readonly __typename?: 'NarrativeReport' } | { readonly __typename?: 'Organization' } | { readonly __typename?: 'OtherProduct' } | { readonly __typename?: 'Partner' } | { readonly __typename?: 'ProgressReport' } | { readonly __typename?: 'Story' } | (
      { readonly __typename?: 'User' }
      & Pick<Types.User, 'id' | 'fullName'>
      & { readonly roles: (
        { readonly __typename?: 'SecuredRoles' }
        & Pick<Types.SecuredRoles, 'canRead' | 'value'>
      ) }
    )> }
  ) };

export type UserLookupItemFragment = (
  { readonly __typename?: 'User' }
  & Pick<Types.User, 'id' | 'fullName'>
  & { readonly roles: (
    { readonly __typename?: 'SecuredRoles' }
    & Pick<Types.SecuredRoles, 'canRead' | 'value'>
  ) }
);

export const UserLookupItemFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"UserLookupItem"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"fullName"}},{"kind":"Field","name":{"kind":"Name","value":"roles"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"canRead"}},{"kind":"Field","name":{"kind":"Name","value":"value"}}]}}]}}]} as unknown as DocumentNode<UserLookupItemFragment, unknown>;
export const UserLookupDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"UserLookup"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"query"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"search"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"query"},"value":{"kind":"Variable","name":{"kind":"Name","value":"query"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"type"},"value":{"kind":"ListValue","values":[{"kind":"EnumValue","value":"User"}]}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"items"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"UserLookupItem"}}]}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"UserLookupItem"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"fullName"}},{"kind":"Field","name":{"kind":"Name","value":"roles"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"canRead"}},{"kind":"Field","name":{"kind":"Name","value":"value"}}]}}]}}]} as unknown as DocumentNode<UserLookupQuery, UserLookupQueryVariables>;