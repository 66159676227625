import { SvgIcon, SvgIconProps } from '@mui/material';

export const OptionsIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 57 57">
    <g>
      <path d="M36.1982 8.15671C33.5534 8.15671 31.4102 10.3113 31.4102 12.9618C31.4102 14.9283 32.6072 16.6269 34.3058 17.3793C34.5395 17.4705 34.7732 17.556 35.0297 17.6244V21.6999C35.0297 23.1648 34.1291 24.5556 32.6072 25.4391L24.7241 29.982C24.3821 30.1815 24.0572 30.4038 23.7608 30.6375C22.2845 31.8345 21.4181 33.5046 21.4181 35.2887V39.5409C21.3497 39.5295 21.2756 39.4953 21.2072 39.4497C19.2293 40.0482 17.7872 41.8722 17.7872 44.0553C17.7872 46.7001 19.9418 48.8433 22.5923 48.8433C25.2428 48.8433 27.3974 46.7001 27.3974 44.0553C27.3974 41.8038 25.8527 39.9228 23.7665 39.4041V35.283C23.7665 34.0062 24.5645 32.775 25.8983 32.0169L33.7814 27.4626C36.0443 26.163 37.3781 24.0084 37.3781 21.6999V17.6244C39.4643 17.1 41.009 15.2133 41.009 12.9618C41.0033 10.3113 38.8601 8.15671 36.1982 8.15671ZM25.049 44.0439C25.049 45.3891 23.9489 46.4892 22.5866 46.4892C21.23 46.4892 20.1242 45.3891 20.1242 44.0439C20.1242 42.6873 21.2243 41.5815 22.5866 41.5815C23.9489 41.5872 25.049 42.6873 25.049 44.0439ZM36.6314 15.3843C36.4889 15.4071 36.3521 15.4185 36.1982 15.4185C35.5655 15.4185 34.967 15.162 34.5338 14.763C34.055 14.3184 33.7472 13.6743 33.7472 12.9618C33.7472 11.6052 34.8473 10.4994 36.1925 10.4994C37.5491 10.4994 38.6549 11.5995 38.6549 12.9618C38.6606 14.1645 37.7942 15.1734 36.6314 15.3843Z" />
      <path d="M22.5182 8.15671C19.8734 8.15671 17.7131 10.3113 17.7131 12.9618C17.7131 15.2361 19.292 17.1342 21.4124 17.6358V30.438C21.6803 29.982 22.0109 29.5602 22.3985 29.1726C22.7975 28.7736 23.2421 28.4202 23.7437 28.1181C23.7551 28.0839 23.7551 28.0611 23.7551 28.0269V17.5959C25.8071 17.043 27.3176 15.1734 27.3176 12.9561C27.3233 10.3113 25.163 8.15671 22.5182 8.15671ZM22.5182 15.4185C21.1616 15.4185 20.0558 14.3184 20.0558 12.9561C20.0558 11.5995 21.1559 10.4937 22.5182 10.4937C23.8748 10.4937 24.9806 11.5938 24.9806 12.9561C24.9806 14.3184 23.8748 15.4185 22.5182 15.4185Z" />
    </g>
  </SvgIcon>
);
