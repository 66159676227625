import type * as Types from '~/api/schema.graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ProjectLookupQueryVariables = Types.Exact<{
  query: Types.Scalars['String']['input'];
}>;


export type ProjectLookupQuery = { readonly search: (
    { readonly __typename?: 'SearchOutput' }
    & { readonly items: ReadonlyArray<{ readonly __typename?: 'DerivativeScriptureProduct' } | { readonly __typename?: 'DirectScriptureProduct' } | { readonly __typename?: 'EthnoArt' } | { readonly __typename?: 'FieldRegion' } | { readonly __typename?: 'FieldZone' } | { readonly __typename?: 'Film' } | { readonly __typename?: 'FinancialReport' } | { readonly __typename?: 'FundingAccount' } | (
      { readonly __typename?: 'InternshipProject' }
      & Pick<Types.InternshipProject, 'id'>
      & { readonly name: (
        { readonly __typename?: 'SecuredString' }
        & Pick<Types.SecuredString, 'value'>
      ) }
    ) | { readonly __typename?: 'Language' } | { readonly __typename?: 'Location' } | (
      { readonly __typename?: 'MomentumTranslationProject' }
      & Pick<Types.MomentumTranslationProject, 'id'>
      & { readonly name: (
        { readonly __typename?: 'SecuredString' }
        & Pick<Types.SecuredString, 'value'>
      ) }
    ) | (
      { readonly __typename?: 'MultiplicationTranslationProject' }
      & Pick<Types.MultiplicationTranslationProject, 'id'>
      & { readonly name: (
        { readonly __typename?: 'SecuredString' }
        & Pick<Types.SecuredString, 'value'>
      ) }
    ) | { readonly __typename?: 'NarrativeReport' } | { readonly __typename?: 'Organization' } | { readonly __typename?: 'OtherProduct' } | { readonly __typename?: 'Partner' } | { readonly __typename?: 'ProgressReport' } | { readonly __typename?: 'Story' } | { readonly __typename?: 'User' }> }
  ) };

export type TranslationProjectLookupQueryVariables = Types.Exact<{
  query: Types.Scalars['String']['input'];
}>;


export type TranslationProjectLookupQuery = { readonly search: (
    { readonly __typename?: 'SearchOutput' }
    & { readonly items: ReadonlyArray<{ readonly __typename?: 'DerivativeScriptureProduct' } | { readonly __typename?: 'DirectScriptureProduct' } | { readonly __typename?: 'EthnoArt' } | { readonly __typename?: 'FieldRegion' } | { readonly __typename?: 'FieldZone' } | { readonly __typename?: 'Film' } | { readonly __typename?: 'FinancialReport' } | { readonly __typename?: 'FundingAccount' } | { readonly __typename?: 'InternshipProject' } | { readonly __typename?: 'Language' } | { readonly __typename?: 'Location' } | (
      { readonly __typename?: 'MomentumTranslationProject' }
      & Pick<Types.MomentumTranslationProject, 'id'>
      & { readonly name: (
        { readonly __typename?: 'SecuredString' }
        & Pick<Types.SecuredString, 'value'>
      ) }
    ) | (
      { readonly __typename?: 'MultiplicationTranslationProject' }
      & Pick<Types.MultiplicationTranslationProject, 'id'>
      & { readonly name: (
        { readonly __typename?: 'SecuredString' }
        & Pick<Types.SecuredString, 'value'>
      ) }
    ) | { readonly __typename?: 'NarrativeReport' } | { readonly __typename?: 'Organization' } | { readonly __typename?: 'OtherProduct' } | { readonly __typename?: 'Partner' } | { readonly __typename?: 'ProgressReport' } | { readonly __typename?: 'Story' } | { readonly __typename?: 'User' }> }
  ) };

export type InternshipProjectLookupQueryVariables = Types.Exact<{
  query: Types.Scalars['String']['input'];
}>;


export type InternshipProjectLookupQuery = { readonly search: (
    { readonly __typename?: 'SearchOutput' }
    & { readonly items: ReadonlyArray<{ readonly __typename?: 'DerivativeScriptureProduct' } | { readonly __typename?: 'DirectScriptureProduct' } | { readonly __typename?: 'EthnoArt' } | { readonly __typename?: 'FieldRegion' } | { readonly __typename?: 'FieldZone' } | { readonly __typename?: 'Film' } | { readonly __typename?: 'FinancialReport' } | { readonly __typename?: 'FundingAccount' } | (
      { readonly __typename?: 'InternshipProject' }
      & Pick<Types.InternshipProject, 'id'>
      & { readonly name: (
        { readonly __typename?: 'SecuredString' }
        & Pick<Types.SecuredString, 'value'>
      ) }
    ) | { readonly __typename?: 'Language' } | { readonly __typename?: 'Location' } | { readonly __typename?: 'MomentumTranslationProject' } | { readonly __typename?: 'MultiplicationTranslationProject' } | { readonly __typename?: 'NarrativeReport' } | { readonly __typename?: 'Organization' } | { readonly __typename?: 'OtherProduct' } | { readonly __typename?: 'Partner' } | { readonly __typename?: 'ProgressReport' } | { readonly __typename?: 'Story' } | { readonly __typename?: 'User' }> }
  ) };

export type ProjectLookupItem_InternshipProject_Fragment = (
  { readonly __typename?: 'InternshipProject' }
  & Pick<Types.InternshipProject, 'id'>
  & { readonly name: (
    { readonly __typename?: 'SecuredString' }
    & Pick<Types.SecuredString, 'value'>
  ) }
);

export type ProjectLookupItem_MomentumTranslationProject_Fragment = (
  { readonly __typename?: 'MomentumTranslationProject' }
  & Pick<Types.MomentumTranslationProject, 'id'>
  & { readonly name: (
    { readonly __typename?: 'SecuredString' }
    & Pick<Types.SecuredString, 'value'>
  ) }
);

export type ProjectLookupItem_MultiplicationTranslationProject_Fragment = (
  { readonly __typename?: 'MultiplicationTranslationProject' }
  & Pick<Types.MultiplicationTranslationProject, 'id'>
  & { readonly name: (
    { readonly __typename?: 'SecuredString' }
    & Pick<Types.SecuredString, 'value'>
  ) }
);

export type ProjectLookupItemFragment = ProjectLookupItem_InternshipProject_Fragment | ProjectLookupItem_MomentumTranslationProject_Fragment | ProjectLookupItem_MultiplicationTranslationProject_Fragment;

export const ProjectLookupItemFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ProjectLookupItem"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Project"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"value"}}]}}]}}]} as unknown as DocumentNode<ProjectLookupItemFragment, unknown>;
export const ProjectLookupDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ProjectLookup"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"query"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"search"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"query"},"value":{"kind":"Variable","name":{"kind":"Name","value":"query"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"type"},"value":{"kind":"ListValue","values":[{"kind":"EnumValue","value":"Project"}]}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"items"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Project"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ProjectLookupItem"}}]}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ProjectLookupItem"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Project"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"value"}}]}}]}}]} as unknown as DocumentNode<ProjectLookupQuery, ProjectLookupQueryVariables>;
export const TranslationProjectLookupDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"TranslationProjectLookup"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"query"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"search"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"query"},"value":{"kind":"Variable","name":{"kind":"Name","value":"query"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"type"},"value":{"kind":"ListValue","values":[{"kind":"EnumValue","value":"TranslationProject"}]}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"items"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"TranslationProject"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ProjectLookupItem"}}]}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ProjectLookupItem"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Project"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"value"}}]}}]}}]} as unknown as DocumentNode<TranslationProjectLookupQuery, TranslationProjectLookupQueryVariables>;
export const InternshipProjectLookupDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"InternshipProjectLookup"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"query"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"search"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"query"},"value":{"kind":"Variable","name":{"kind":"Name","value":"query"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"type"},"value":{"kind":"ListValue","values":[{"kind":"EnumValue","value":"InternshipProject"}]}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"items"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"InternshipProject"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ProjectLookupItem"}}]}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ProjectLookupItem"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Project"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"value"}}]}}]}}]} as unknown as DocumentNode<InternshipProjectLookupQuery, InternshipProjectLookupQueryVariables>;