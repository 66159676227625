import type * as Types from '~/api/schema.graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CreateEthnoArtMutationVariables = Types.Exact<{
  input: Types.CreateEthnoArtInput;
}>;


export type CreateEthnoArtMutation = { readonly createEthnoArt: (
    { readonly __typename?: 'CreateEthnoArtOutput' }
    & { readonly ethnoArt: (
      { readonly __typename?: 'EthnoArt' }
      & Pick<Types.EthnoArt, 'id'>
      & { readonly name: (
        { readonly __typename?: 'SecuredString' }
        & Pick<Types.SecuredString, 'value'>
      ) }
    ) }
  ) };

export type EthnoArtsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type EthnoArtsQuery = { readonly ethnoArts: (
    { readonly __typename?: 'EthnoArtListOutput' }
    & { readonly items: ReadonlyArray<(
      { readonly __typename?: 'EthnoArt' }
      & Pick<Types.EthnoArt, 'id'>
      & { readonly name: (
        { readonly __typename?: 'SecuredString' }
        & Pick<Types.SecuredString, 'value'>
      ) }
    )> }
  ) };


export const CreateEthnoArtDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateEthnoArt"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateEthnoArtInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createEthnoArt"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ethnoArt"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"value"}}]}}]}}]}}]}}]} as unknown as DocumentNode<CreateEthnoArtMutation, CreateEthnoArtMutationVariables>;
export const EthnoArtsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"EthnoArts"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ethnoArts"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"items"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"value"}}]}}]}}]}}]}}]} as unknown as DocumentNode<EthnoArtsQuery, EthnoArtsQueryVariables>;