import type * as Types from '~/api/schema.graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type RequestFileUploadMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type RequestFileUploadMutation = { readonly requestFileUpload: (
    { readonly __typename?: 'RequestUploadOutput' }
    & Pick<Types.RequestUploadOutput, 'id' | 'url'>
  ) };


export const RequestFileUploadDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RequestFileUpload"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"requestFileUpload"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"url"}}]}}]}}]} as unknown as DocumentNode<RequestFileUploadMutation, RequestFileUploadMutationVariables>;