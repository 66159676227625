import { SvgIcon, SvgIconProps } from '@mui/material';

export const PlantIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 17">
    <g>
      <path d="M11.527 7.177a3.336 3.336 0 01-2.357-.956 3.234 3.234 0 01-.967-2.331c-.004-.477.1-.949.302-1.382a3.25 3.25 0 01.868-1.124 5.594 5.594 0 013.74-1.192c.396 0 .771.02 1.14.04.325.018.63.036.913.036.173.001.345-.007.516-.025a.284.284 0 01.297.178.275.275 0 01-.103.328c-.557.381-.69 1.297-.83 2.265-.172 1.193-.367 2.545-1.397 3.391-.591.5-1.344.773-2.121.772zM13.113.75a5.066 5.066 0 00-3.379 1.068c-.306.255-.55.573-.718.932-.167.358-.252.75-.249 1.145a2.682 2.682 0 00.802 1.936 2.741 2.741 0 001.96.793 2.72 2.72 0 001.756-.642c.866-.71 1.037-1.896 1.202-3.043.051-.733.243-1.45.567-2.11-.265 0-.54-.02-.831-.036a17.288 17.288 0 00-1.11-.043h0z" />
      <path d="M8.304 13.177a.284.284 0 01-.277-.223 8.524 8.524 0 01-.18-1.74c.08-2.254.843-4.432 2.189-6.254.63-.91 1.362-1.746 2.184-2.492a.284.284 0 01.467.23.275.275 0 01-.097.191 14.223 14.223 0 00-2.1 2.401 10.64 10.64 0 00-2.08 5.924c.001.546.057 1.091.17 1.627a.276.276 0 01-.156.308.285.285 0 01-.121.027v.001zM3.792 8.658a2.428 2.428 0 01-1.37-.444c-.4-.283-.703-.68-.868-1.137a3.514 3.514 0 01-.155-.992c-.02-.52-.031-.78-.912-1.181a.28.28 0 01-.165-.307.278.278 0 01.267-.227c.319-.032.635-.09.945-.173a7.246 7.246 0 011.668-.25c.594-.007 1.18.14 1.698.427.359.194.658.48.867.827.209.347.319.744.319 1.148-.002.41-.112.814-.32 1.169a2.26 2.26 0 01-.832.833c-.346.2-.74.306-1.142.307zm-2.369-3.86A1.447 1.447 0 011.97 6.06c0 .286.042.57.125.845.139.404.426.742.804.948l.011.006c.267.157.572.24.883.241.302 0 .6-.079.862-.23a1.7 1.7 0 00.627-.628c.16-.271.245-.579.246-.893a1.657 1.657 0 00-.237-.866 1.682 1.682 0 00-.656-.62 2.874 2.874 0 00-1.425-.358 6.698 6.698 0 00-1.538.235l-.249.057z" />
      <path d="M8.304 13.177a.284.284 0 01-.28-.262c-.213-3.5-3.671-5.787-3.705-5.81a.279.279 0 01-.082-.385.282.282 0 01.39-.08 10.33 10.33 0 011.89 1.678 7.64 7.64 0 012.07 4.563.277.277 0 01-.07.202.284.284 0 01-.195.093l-.018.001zM10.773 4.72a.283.283 0 01-.199-.082.277.277 0 01-.082-.197V2.347c0-.074.03-.144.082-.197a.284.284 0 01.482.197v2.094a.275.275 0 01-.174.258.284.284 0 01-.109.021zM12.891 4.023H11.48a.284.284 0 01-.2-.082.277.277 0 010-.395.284.284 0 01.2-.081h1.411a.277.277 0 110 .558z" />
      <path d="M14.03 15.81H2.183c-.375 0-.733-.148-.998-.41a1.387 1.387 0 010-1.972c.265-.261.623-.408.998-.408H14.03a1.425 1.425 0 011.065.482.276.276 0 01-.028.393.285.285 0 01-.398-.028.848.848 0 00-.64-.29H2.184a.853.853 0 00-.557.268.834.834 0 000 1.139c.145.155.344.25.557.267H14.03c.074 0 .146.03.199.082a.278.278 0 01-.2.476z" />
    </g>
  </SvgIcon>
);
